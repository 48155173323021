// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { RootState } from "../../redux/reducers";

// Material UI
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  getGridStringOperators,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { List, ListItem, Typography, ListItemText, Divider } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

// Fetch
import { getAll } from "../../fetchs/get";

// Interface
import { ILot } from "../../interface/lot";
import { IProject } from "../../interface/project";

// Components
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";

function CustomToolbar(project : string) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ mx: 1 }} />
      <GridToolbarFilterButton sx={{ mx: 1 }} />
      <GridToolbarExport sx={{ mx: 1 }} csvOptions={{delimiter: ';', utf8WithBom: true, fileName: "Liste des lots du projet "}} />
    </GridToolbarContainer>
  );
}

let defaultLotCosts = {
  C: 0,
  RTUD: 0,
  RTUI: 0,
  QS: 0,
  QC: 0,
  QE: 0,
  RS: 0,
  RC: 0,
  RE: 0,
  D: 0,
  PC: 0,
  A: 0,
};

// Formatage des nombres au format français avec le symbole €
const formatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});

export default function ProjectCostsSummary({
  project,
  setLotId,
  lotId,
}: {
  project: IProject;
  setLotId: any;
  lotId: string;
}) {
  let navigate = useNavigate();
  const [ArrayLot, setArrayLot] = useState<ILot[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "name",
      sort: "asc",
    },
  ]);

  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  useEffect(() => {
    if (lotId) setLotId(lotId);
  });

  const processLots = (lots: ILot[]) => {
    if (lots) {
      lots.forEach((lot: ILot) => {
        if (lot.total === undefined) {
          lot.total = 0;
          lot.lotCosts = defaultLotCosts;
        }
      });
      setArrayLot(lots);
    }
  };

  const getLotsProject = () => {
    if (token)
      getAll(
        token,
        `/projects/${project._id}/lots`,
        processLots,
        () => dispatch(sessionEnd()),
        (err: any) => {
          navigate(`/projets/`)
          dispatch(showSnackbar("Erreur lors de la récupération des lots : " + err?.error, "error"));
        }
      );
  };

  useEffect(() => {
    getLotsProject();
  }, [project.lots]);

  // Ici l'on map sur les élèments présent en BDD, ce qui pose problème car lorsqu'on ajoute un lot depuis cette page aucune requête d'insertion en BDD ne se fait, on update juste project.lots. L'affichage de ne s'actualise pas il faudrait pour y remédier faire la requête d'insertion en BDD au moment de l'ajout du lot sur cette page ou mapper sur un tableau différents prenant en compte les nouveaux élèments dans project.lots
  let totaux = {
    preparatory: 0,
    preparatoryWriteTest: 0,
    preparatoryExecuteTest: 0,
    preparatoryPiloting: 0,
    preparatoryRealDev: 0,
    preparatoryRealInfra: 0,
    preparatoryAccompaniement : 0,
  };
  const rows: GridRowsProp = ArrayLot.map((lot: ILot) => {
    let preparatory = lot.total;
    let preparatoryWriteTest = lot.lotCosts.QC! ;
    let preparatoryExecuteTest = lot.lotCosts.QE!;
    let preparatoryPiloting = lot.lotCosts.PC!;
    let preparatoryRealDev =lot.lotCosts.RTUD!;
    let preparatoryRealInfra =lot.lotCosts.RTUI!;  
    let preparatoryAccompaniement = lot.lotCosts.A!;

    totaux.preparatory += preparatory;
    totaux.preparatoryPiloting += preparatoryPiloting;
    totaux.preparatoryExecuteTest += preparatoryExecuteTest;
    totaux.preparatoryWriteTest += preparatoryWriteTest;
    totaux.preparatoryRealDev += preparatoryRealDev;
    totaux.preparatoryRealInfra += preparatoryRealInfra;
    totaux.preparatoryAccompaniement += preparatoryAccompaniement;
    
    return {
      id: lot._id,
      name: lot.name,
      preparatory: preparatory,
      preparatoryWriteTest : preparatoryWriteTest,
      preparatoryExecuteTest : preparatoryExecuteTest,
      preparatoryPiloting : preparatoryPiloting,
      preparatoryRealDev : preparatoryRealDev,
      preparatoryRealInfra : preparatoryRealInfra,
      preparatoryAccompaniement : preparatoryAccompaniement,
    };
  });
  
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom du lot",
      flex: 2,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "preparatory",
      headerName: "Coût Total du lot",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",  
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryRealDev",
      headerName: "Real/Dev",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryRealInfra",
      headerName: "Real/Infra",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryWriteTest",
      headerName: "Ecriture Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryExecuteTest",
      headerName: "Exécution Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryPiloting",
      headerName: "Pilotage",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    },
    {
      field: "preparatoryAccompaniement",
      headerName: "Accompagnement",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
    }
  ];

  /*Lorsque l'on clique sur la cellules contenant un lot, on set le lot permettant ainsi de l'afficher*/
  const handleChange = (lot: any) => {
    if (setLotId) {
      setLotId(lot.id);
    }
  };

  const TextOneLineEllipsis: SxProps<Theme> = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  return (
    <div className="container-projectCostsSummary">
      <h3 className="projectCostsSummary__title">Synthèse du projet</h3>
      <div
        style={{ height: "75px", width: "100%", border: "rgba(0,0,0,0.1) solid 1px", borderRadius: 6, marginBottom: 5 }}
      >
        <List style={{ display: "flex", flexDirection: "row", padding: 0, height: "full" }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6" style={{ fontSize: 18 }}>
                  Totaux
                </Typography>
              }
              disableTypography
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Coût Total du projet"
              secondary={formatter.format(totaux.preparatory)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Real/Dev"
              secondary={formatter.format(totaux.preparatoryRealDev)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Real/Infra"
              secondary={formatter.format(totaux.preparatoryRealInfra)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Ecriture Test"
              secondary={formatter.format(totaux.preparatoryWriteTest)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Exécution Test"
              secondary={formatter.format(totaux.preparatoryExecuteTest)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Pilotage"
              secondary={formatter.format(totaux.preparatoryPiloting)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
          <Divider orientation="vertical" variant="middle" flexItem />
          <ListItem>
            <ListItemText
              primary="Accompagnement"
              secondary={formatter.format(totaux.preparatoryAccompaniement)}
              sx={TextOneLineEllipsis}
            />
          </ListItem>
        </List>
      </div>
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGrid
          disableRowSelectionOnClick={true}
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[8]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          onCellClick={(row) => {
            handleChange(row);
          }}
        />
      </div>
    </div>
  );
}
