// Material UI
import { TextField } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Interface
import { ITask } from "../../interface/task";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { changeValue } from "../../redux/actions/index";

const LotCosts = ({ lot }: { lot: string }) => {
  let tasksCharges: Array<ITask> = useSelector((state: RootState) => {
    return state.lotSynthesis.tasksCharges;
  });

  let totalCharges: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.totalCharges;
  });

  let PPR: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.PPR;
  });

  let lotCosts: Array<any> = useSelector((state: RootState) => {
    return state.lotSynthesis.lotCosts;
  });

  let total: number | undefined = useSelector((state: RootState) => {
    return state.lotSynthesis.total;
  });

  let dispatch = useDispatch();

  /* Fonction Affichage des coûts dans les cellules */
  const displayCell = (cost: any, displayAdornments = false) => 
    Object.keys(cost).map((key) => {
      let element = cost[key];
      if (key !== "name" && key !== "description") {
        return (
          <TableCell key={key} sx={{ padding: "5px" }}>
            <TextField
              key={key}
              name={key}
              id="filled-basic"
              type="text"
              variant="filled"
              value={(cost === lotCosts && displayAdornments) ? element+" €" : element ? element : 0}
              onChange={(e) => {
                dispatch(changeValue(e.target.value, e.target.name));
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  padding: 5,
                },
              }}
              style={{width: "100%"}}
            />
          </TableCell>
        );
      }
    });

  return (
    <TableContainer sx={{ mt: 4 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#F0F0F0" }}>
          <TableRow>
            <TableCell sx={{ padding: "10px", width: "175px" }}>Description</TableCell>
            <TableCell sx={{ padding: "10px", width: "120px" }}>Concept.</TableCell>
            <TableCell sx={{ padding: "10px" }}>Real/Dev</TableCell>
            <TableCell sx={{ padding: "10px" }}>Real/Infra</TableCell>
            <TableCell sx={{ padding: "10px" }}>Qual/Stra</TableCell>
            <TableCell sx={{ padding: "10px" }}>Qual/CCT</TableCell>
            <TableCell sx={{ padding: "10px" }}>Qual/Exec</TableCell>
            <TableCell sx={{ padding: "10px" }}>Rec/Stra</TableCell>
            <TableCell sx={{ padding: "10px" }}>Rec/CCT</TableCell>
            <TableCell sx={{ padding: "10px" }}>Rec/Exec</TableCell>
            <TableCell sx={{ padding: "10px" }}>Deploy.</TableCell>
            <TableCell sx={{ padding: "10px" }}>Pil.&C.</TableCell>
            <TableCell sx={{ padding: "10px" }}>Acc</TableCell>
            <TableCell sx={{ padding: "10px" }}>Guar.</TableCell>
            <TableCell sx={{ padding: "10px", backgroundColor: "#ffffff", color: "#ffffff", borderBottom: "none" }}>Supprim</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: "0px" }}>Charge Calculée</TableCell>
            {displayCell(tasksCharges)}
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "0px" }}>Marge pour Risque</TableCell>
            {displayCell(PPR)}
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "0px" }}>Charge Totale</TableCell>
            {displayCell(totalCharges)}
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "0px" }}>Coût Total par Profil</TableCell>
            {displayCell(lotCosts, true)}
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "0px" }}>Coût Total du lot</TableCell>
            <TableCell key={"total"} sx={{ padding: "0px" }}>
              <TextField
                key={"total"}
                id="standard-number"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                value={total + " €"}
                style={{width: "100px"}}
                inputProps={{
                  style: {
                    padding: 5,
                  },
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LotCosts;
