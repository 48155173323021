import { useState, useEffect } from "react";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

// Composant
import SelectLots from "../components/Lots/SelectLots";

// Redux
import { sessionEnd } from "../redux/actions";
import { useDispatch } from "react-redux";

// fetch
import { getWithFilter } from "../fetchs/get";

import { useNavigate } from "react-router-dom";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "22ch",
      },
    },
  },
}));

export default function NavTab({
  text,
  // Le boolean search permet d'afficher ou non la barre de recherche
  search = false,
  token,
  setProject,
  setError,
  project,
  setOpenModal,
  setLotId,
  lotId,
  showDialog,
  displayReturn = false,
}: {
  text: string;
  search?: boolean;
  token?: string;
  setProject?: any;
  setError?: any;
  project?: any;
  setOpenModal?: Function;
  setLotId?: Function;
  lotId?: string;
  showDialog?: Boolean;
  displayReturn?: Boolean;
}) {
  let navigate = useNavigate();
  useEffect(() => {
    // logique à exécuter lorsque lotId ou project change
  }, [lotId, project]);
 
  const displayTitle = () => {
    return (
      <>
          {displayReturn && <ArrowBackIcon
            className="backToProjectArrow"
            sx={{ mr: 3 }}
            onClick={() => {
                if (lotId && setLotId) {
                  if (showDialog) {
                    navigate("/projet/" + project._id);
                  } else {
                    setLotId("");
                  }
                } else {
                  navigate("/projets/");
                }
              }}
          />}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "block" },
          }}
        >
          {text}
        </Typography>
      </>
    );
  };

  let dispatch = useDispatch();

  return (
    <div className="navDetails" style={{ height: "64px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {displayTitle()}
            {/*  si jamais lots est un tableau vide n'affiche pas le composant */}
            <Box sx={{ display: "flex", alignItems: "center" }} className="selectLots">
              {project && project.lots.length > 0 && (
                <SelectLots lots={project.lots} setLotId={setLotId} lotId={lotId ? lotId : ""} />
              )}
              {setOpenModal && (
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{ mx: 2 }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Ajouter un lot
                </Button>
              )}
            </Box>

            {search ? (
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Recherche d'un projet"
                  inputProps={{ "aria-label": "search" }}
                  sx = {{width: "100%"}}
                  onChange={(e) => {
                    if (token) {
                      getWithFilter(
                        token,
                        "/projects/filter/",
                        e.target.value,
                        setProject,
                        () => {
                          dispatch(sessionEnd());
                        },
                        setError
                      );
                  }}}
                />
              </Search>
            ) : null}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
