// React
import { useState, Fragment } from "react";

// Material UI
import { Typography, Divider, Menu, Button, Checkbox, FormControlLabel, Chip, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Item from "@mui/material/ListItem";
import Fade from "@mui/material/Fade";

// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Tools
import { extractActionIcon, extractActionColor } from "../../../tools/history";
import { handleFilterClick, handleFilterClose, handleFilterChange, handleSelectAll } from "../../../tools/history";

const ActionFilter = ({
  currentActions,
  setCurrentActions,
  actions,
}: {
  currentActions: string[];
  setCurrentActions: React.Dispatch<React.SetStateAction<string[]>>;
  actions: string[];
}) => {
  const [actionFilterAnchorEl, setActionFilterAnchorEl] = useState<null | HTMLElement>(null);

  const actionFilterOpen = Boolean(actionFilterAnchorEl);

  return (
    <Fragment>
      <Menu
        id="person-filter"
        anchorEl={actionFilterAnchorEl}
        open={actionFilterOpen}
        onClose={handleFilterClose(setActionFilterAnchorEl)}
        MenuListProps={{
          "aria-labelledby": "person-filter",
        }}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: 600,
            width: "27ch",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={currentActions.length === actions.length}
              indeterminate={currentActions.length > 0 && currentActions.length < actions.length}
              onChange={handleSelectAll(setCurrentActions, actions, currentActions)}
              size="small"
            />
          }
          label={<span style={{ fontWeight: 500 }}>Select All</span>}
          sx={{
            pl: 2,
            pb: 0.5,
          }}
        />

        <Divider sx={{ mb: 0.5 }} />

        {actions.map((action) => (
          <MenuItem key={action} dense sx={{p: 0}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentActions.includes(action)}
                  onChange={handleFilterChange(setCurrentActions, currentActions)(action)}
                  size="small"
                />
              }
              label={
                <Chip 
                  variant="outlined"
                  label={action.charAt(0) + action.slice(1).toLowerCase()}
                  color={extractActionColor(action)}
                  icon={extractActionIcon(action)}
                />
              }
              sx={{
                pl: 5,
                py: .5,
              }}
            />
          </MenuItem>
        ))}
        <Divider />
        <Button
          size="small"
          onClick={() => {
            setCurrentActions([]);
          }}
          sx={{ ml: 2 }}
        >
          Clear ({currentActions.length})
        </Button>
      </Menu>
      <Item sx={{ width: 120 }}>
        <Button
          id="person-filter"
          aria-controls="person-filter"
          aria-haspopup="true"
          aria-expanded={actionFilterOpen ? "true" : undefined}
          onClick={handleFilterClick(setActionFilterAnchorEl)}
          endIcon={<ExpandMoreRoundedIcon />}
        >
          <Typography variant="subtitle2">Action</Typography>
        </Button>
      </Item>
    </Fragment>
  );
};

export default ActionFilter;
