// React
import { useState, Fragment } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { showSnackbar } from "../../redux/actions/index";

// Fetch
import { fetchPostProtect } from "../../fetchs/post";

// MaterialUI
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";

// Interface
import { MatriceWrapperClientsData } from "../../interface/matrice";

// Components
import MatrixDialogEdit from "./MatrixDialogEdit";

// Icons
import EditIcon from "@mui/icons-material/Edit";

const MasterMatrixButton = ({
  masterMatrice,
  updateOneMatrix,
}: {
  masterMatrice: MatriceWrapperClientsData;
  updateOneMatrix: Function;
}) => {
  const [openModal, setOpenModal] = useState<Boolean>(false);

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  /**
   * Envoie la matrice master modifiée au back pour la mettre à jour dans la base de données
   * Ouverture d'une snackbar en fonction du résultat de la requête
   * @param updatedMatriceData matrice master modifiée
   */
  const pushModifiedMasterMatrice = (updatedMatriceData: MatriceWrapperClientsData) => {
    const body: MatriceWrapperClientsData = { ...updatedMatriceData };
    if (token) {
      fetchPostProtect(token, "/matrice/master/update/project_master", JSON.stringify(body)).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Matrice master modifiée avec succès", "success"));
        } else {
          res.json().then((err) => {
            console.log(err);
            dispatch(showSnackbar("Erreur lors de la modification de la matrice master : " + err.error, "error"));
          });
        }
      });
    }
  };

  return (
    <Fragment>
      {openModal && masterMatrice && (
        <MatrixDialogEdit
          matrice={masterMatrice}
          setOpenModal={setOpenModal}
          pushModifiedMatrice={pushModifiedMasterMatrice}
          isMaster={true}
          updateOneMatrix={updateOneMatrix}
        />
      )}
      <MenuItem key={"editMaster"} sx={{ m: 1, py: 1 }} onClick={() => setOpenModal(true)}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText sx={{ width: "fit-content" }}>Editer la matrice de base</ListItemText>
      </MenuItem>
    </Fragment>
  );
};

export default MasterMatrixButton;
