// React
import { useState, Fragment } from "react";

// Material UI
import { Typography, Divider, Menu, Button, Box } from "@mui/material";
import Item from "@mui/material/ListItem";
import Fade from "@mui/material/Fade";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";
// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Tools
import { handleFilterClick, handleFilterClose } from "../../../tools/history";
const DateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: {
  startDate: dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  endDate: dayjs.Dayjs;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
}) => {
  const [dateFilterAnchorEl, setDateFilterAnchorEl] =
    useState<null | HTMLElement>(null);

  const dateFilterOpen = Boolean(dateFilterAnchorEl);
  const adjustStartDate = (date: Dayjs) => {
    return date.startOf("day");
  };

  const adjustEndDate = (date: Dayjs) => {
    return date.endOf("day");
  };
  return (
    <Fragment>
      <Menu
        id="date-filter"
        anchorEl={dateFilterAnchorEl}
        open={dateFilterOpen}
        onClose={handleFilterClose(setDateFilterAnchorEl)}
        MenuListProps={{
          "aria-labelledby": "date-filter",
        }}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: 600,
            width: "25ch",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de début"
              value={startDate}
              onChange={(newStartDate) => {
                if (newStartDate) {
                  const adjustedDate = adjustStartDate(newStartDate);
                  setStartDate(adjustedDate);
                }
              }}
            />
          </LocalizationProvider>
          <Divider sx={{ my: 3 }} />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de fin"
              value={endDate}
              onChange={(newEndDate) => {
                if (newEndDate) {
                  const adjustedDate = adjustEndDate(newEndDate);
                  setEndDate(adjustedDate);
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </Menu>
      <Item sx={{ width: 120 }}>
        <Button
          id="date-filter"
          aria-controls="date-filter"
          aria-haspopup="true"
          aria-expanded={dateFilterOpen ? "true" : undefined}
          onClick={handleFilterClick(setDateFilterAnchorEl)}
          endIcon={<ExpandMoreRoundedIcon />}
        >
          <Typography variant="subtitle2">Date</Typography>
        </Button>
      </Item>
    </Fragment>
  );
};

export default DateFilter;
