import { useState, useEffect } from "react";

// MaterialUI
import { Card, Alert, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

// Layout
import NavTab from "../../layout/NavTab";

// Fetch
import { getOne } from "../../fetchs/get";
import { fetchPutProtect } from "../../fetchs/put";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// React router
import { useParams, useLocation } from "react-router-dom";

// Component
import AddLot from "../../components/Lots/AddLot";
import ProjectDetails from "../../components/Projects/ProjectDetails";
import LotDetails from "../Lots/LotDetails";
import { IProject } from "../../interface/project";

// Tools
import { useNavigate } from "react-router-dom";
interface IFormValues {
  name: string;
  description: string;
  client: string;
}


const Project = () => {
  const { state } = useLocation();
  let navigate = useNavigate();

  const [project, setProject] = useState<IProject>();
  const [error, setError] = useState("");
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isModifiedMatrice, setIsModifiedMatrice] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [lotId, setLotId] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const dispatch = useDispatch();
  const params = useParams();

  const getProject = () => {
    if (token && params.projectId) {
      getOne(
        token,
        "/projects/",
        params.projectId,
        setProject,
        () => {
          dispatch(sessionEnd());
        },
        (err: any) => { 
          navigate(`/projets/`);
          dispatch(showSnackbar("Erreur : " + err?.error, "error"));
        }
      );
    }
  };

  useEffect(() => {
    getProject();
    if (state === "projectSuccess") {
      dispatch(showSnackbar("Projet créé avec succès", "success"));
    }
  }, []);

  const updateProject = async (values: IFormValues) => {
    const body = JSON.stringify({
      id: project?._id,
      name: values.name,
      description: values.description,
      client: values.client,
    });
    if (token) {
      fetchPutProtect(token, "/projects/update", body)
        .then((response) => {
          if (response.status === 200) {
            setError("");
            dispatch(showSnackbar("Metadonnées du projet modifiées", "success"));
            response
              .json()
              .then(() => {
                setIsModified(false);
                setIsModifiedMatrice(false);
                if (token && params.projectId) {
                  getOne(token, "/projects/", params.projectId, setProject, () => dispatch(sessionEnd()), setError);
                }
              })
              .catch((err) => console.error(err));
          } else if (response.status === 401) {
            dispatch(sessionEnd());
          } else {
            response.json().then((err) => {
              setError("Erreur lors de la modification du projet : " + err.error);
              dispatch(showSnackbar("Erreur lors de la modification du projet : " + err.error, "error"));
            });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Box className="projectPageContainer" component="main">
      <NavTab
        text={lotId ? "Détails du lot : " : "Projet : " + (project?.name || "")}
        project={project}
        setOpenModal={setOpenModal}
        setLotId={setLotId}
        lotId={lotId}
        showDialog={showDialog}
        displayReturn={true}
      />

      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: isModified ? "rgba(255,255,255,0.45)" : "white",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {project?.client ? (
          //TODO : css style scrollbar size 
          <Fade in={true} className='scrollbar' style={{
            overflow: "scroll"
          }}>
            <div>
              {error ? <Alert severity="error">{error}</Alert> : null}

              {!lotId ? (
                <ProjectDetails
                  project={project}
                  isModified={isModified}
                  setIsModified={setIsModified}
                  isModifiedMatrice={isModifiedMatrice}
                  setIsModifiedMatrice={setIsModifiedMatrice}
                  updateProject={updateProject}
                  setLotId={setLotId}
                  lotId={lotId}
                />
              ) : (
                <LotDetails projectId={project._id} lotId={lotId} setLotId={setLotId} showDialog={showDialog} setShowDialog={setShowDialog} refreshProject={getProject}/>
              )}

              <AddLot
                project={project}
                setProject={setProject}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setLotId={setLotId}
                lotId={lotId}
              />
            </div>
          </Fade>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            Chargement ...
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default Project;
