// Import Redux
import { useDispatch } from "react-redux";
import { sessionStart } from "../../redux/actions";

// Import React-router
import { useNavigate } from "react-router-dom";

// Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";

// fetch
import { fetchPost } from "../../fetchs/post";

// Import MaterialUI
import { Box } from "@mui/system";
import { TextField, Button, Alert } from "@mui/material";

// const validationSchema = yup.object({
//   email: yup
//     .string()
//     .trim()
//     .email("Ce n'est pas une adresse email valide")
//     .required("Le nom du lot est obligatoire"),
//   password: yup
//     .string()
//     .trim()
//     .required("Le mot de passe est obligatoire"),
// });

interface IFormValues {
  email: string;
  password: string;
}

const LoginLocal = ({
  setError,
  error,
}: {
  setError: (error: string) => void;
  error: string;
}) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const connectLocal = (values: IFormValues) => {
    const body = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    fetchPost("/auth/login", body)
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((result) => {
              let token: string;
              token = result.token;
              let username: string = result.username;
              dispatch(sessionStart(token, username));
              navigate("/");
            })
            .catch((err) => console.error(err));
        } else if (response.status === 401) {
          setError("Vos informations de connexion sont erronés");
        }
      })
      .catch((err) => setError(err));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      connectLocal(values);
    },
  });
  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      {/* <TextField
        margin="normal"
        required
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
      /> */}
      {/* <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        label="Mot de passe"
        type="password"
        id="password"
        autoComplete="current-password"
      /> */}
      {error ? <Alert severity="error">{error}</Alert> : null}
      {/* <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 0 }}>
        Se connecter
      </Button> */}
    </Box>
  );
};

export default LoginLocal;
