import { useState, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions";


// MaterialUI et Layout
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Card, Alert } from "@mui/material";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import IconButton from "@mui/material/IconButton";
import NavTab from "../../layout/NavTab";

// Fetchs
import { getAll } from "../../fetchs/get";
import { deleteOne } from "../../fetchs/delete";
import { fetchPostProtect } from "../../fetchs/post";

// Interface
import { IProject } from "../../interface/project";

// Tools
import { useNavigate } from "react-router-dom";

// Components
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ mx: 1 }} />
      <GridToolbarFilterButton sx={{ mx: 1 }} />
      <GridToolbarExport sx={{ mx: 1 }} csvOptions={{delimiter: ';', utf8WithBom: true, fileName: "Liste des projets"}} />
    </GridToolbarContainer>
  );
}
const ListProjects = () => {
  // Voir pour le type
  const [projects, setProjects] = useState<IProject[]>([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [projectId, setProjectId] = useState();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const getAllProjects = () => {
    if (token) {
      getAll(
        token,
        "/projects/",
        setProjects,
        () => {
          dispatch(sessionEnd());
        },
        setError
      );
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const handleClickOpen = (id: any) => {
    setProjectId(id);
    setOpen(true);
  };

  const handleClickOpenDuplicate = (id: any) => {
    setProjectId(id);
    setOpenConfirmCopy(true);
    return id;
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirmCopy(false);
  };

  const duplicateProject = () => {
    if (token && projectId) {
      const project = projects.find((p: IProject) => p._id === projectId);
      if (project) {
        const requestBody = JSON.stringify({
          name: project.name,
          _id: project._id
        })
        fetchPostProtect(token, "/projects/duplicate", requestBody)
        .then((res) => {
          setOpenConfirmCopy(false);
          dispatch(showSnackbar("Projet dupliqué avec succès !", "success"));
          if (res.status === 200 && token) {
            getAll(token, "/projects/", setProjects, () => dispatch(sessionEnd()), setError);
          } else if (res.status === 401) {
            dispatch(sessionEnd());
          } else if(res.status === 404 && token) {
            getAll(token, "/projects/", setProjects, () => dispatch(sessionEnd()), setError);
            res.json().then((err) => {
              setError(err.error);
              dispatch(showSnackbar("Erreur lors de la duplication du projet : " + err.error, "error"));
            });
          }
        })
        .catch((err) => setError(err));
    } else {
      dispatch(showSnackbar("Erreur lors de la duplication du projet : Id du projet introuvable", "error"));
    }
    }
  };

  /*Fonction permettant de supprimer un projet sur la page de liste de projets*/
  const deleteProject = () => {
    let id = projectId;
    if (token) {
      deleteOne(token, "/projects/", id).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Projet supprimé avec succès !", "success"));
          getAllProjects();
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            setError(err.error);
            dispatch(showSnackbar("Erreur lors de la suppression du projet : " + err.error, "error"));
          });
        }
      });
    }
    handleClose();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "client",
      headerName: "Client",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "author",
      headerName: "Auteur",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "lots",
      headerName: "Lots",
      flex: 1,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "createdAt",
      headerName: "Crée le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "updatedAt",
      headerName: "Mis à jour le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.updatedAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "copy",
      headerName: "Dupliquer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <ContentCopyTwoToneIcon sx={{ color: "orange" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
    {
      field: "delete",
      headerName: "Supprimer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <DeleteTwoToneIcon sx={{ color: "red" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
  ];

  const rows: GridRowsProp = projects.map((project: IProject) => {
    return project.client
      ? {
          id: project._id,
          name: project.name,
          client: project.client.name,
          author: project.author,
          lots: project.lots.length,
          createdAt: project.createdAt,
          updatedAt: project.updatedAt,
        }
      : {
          id: project._id,
          name: project.name,
          author: project.author,
          lots: project.lots.length,
          createdAt: project.createdAt,
          updatedAt: project.updatedAt,
        };
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  return (
    <div className="listProjects">
      <NavTab text="Liste des projets" search={true} token={token} setProject={setProjects} setError={setError} />
      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: "white",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {error ? <Alert severity="error">{error}</Alert> : null}

        <DataGrid
          disableRowSelectionOnClick={true}
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          pageSizeOptions={[8]}
         onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            height: "100%",
          }}
          onCellClick={(row, column, event) => {
            row.field === "delete"
              ? handleClickOpen(row.id)
              : row.field === "copy"
              ? handleClickOpenDuplicate(row.id)
              : navigate(`/projet/${row.id}`);
          }}
        />
      </Card>
      {open && (
        <AlerteDialog
          open={open}
          handleClose={handleClose}
          deleteProject={deleteProject}
          title={"Souhaitez vous vraiment supprimer ce projet ?"}
          contentText={
            "Attention vous êtes sur le point de supprimer définitivement ce projet. Cette action est irréversible et supprimera tous les lots et matrices associés à ce projet."
          }
        />
      )}
      {openConfirmCopy && (
        <AlerteDialog
          open={openConfirmCopy}
          handleClose={handleClose}
          deleteProject={duplicateProject}
          title={"Dupliquer le projet."}
          contentText={"Souhaitez vous dupliquer ce projet ?"}
        />
      )}
    </div>
  );
};

export default ListProjects;
