// React
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
// Material UI
import { Card, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import HistoryGrid from "../../components/History/HistoryGrid";
import FiltersWrapper from "../../components/History/FiltersWrapper";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { showSnackbar } from "../../redux/actions/index";
// Types
import { IHistory } from "../../interface/history";

import { fetchPostProtect } from "../../fetchs/post";

const History = () => {
  const currentDate = dayjs(new Date().toISOString());
  const [ready, setReady] = useState<boolean>(false);
  const [data, setData] = useState<IHistory[]>([]);
  const [currentNames, setCurrentNames] = useState<string[]>([]);
  const [currentActions, setCurrentActions] = useState<string[]>([]);
  const [currentStatus, setCurrentStatus] = useState<string[]>([]);
  const [currentEndpoint, setCurrentEndpoint] = useState<string[]>([]);
  const [currentEntities, setCurrentEntities] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(currentDate.startOf("day"));
  const [endDate, setEndDate] = useState<Dayjs>(currentDate.endOf("day"));
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => {
    return state.user.token;
  });
  useEffect(() => {
    postSearch();
  }, [page, pageSize]);
  const postSearch = () => {
    const body = {
      names: currentNames,
      actions: currentActions,
      status: currentStatus,
      endpoints: currentEndpoint,
      entities: currentEntities,
      startDate: startDate,
      endDate: endDate,
      page: page,
      pageSize: pageSize,
    };
    if (startDate.isAfter(endDate)) {
      dispatch(
        showSnackbar("La date de début doit être avant la date de fin", "error")
      );
      return;
    }

    if (token) {
      fetchPostProtect(
        token,
        "/history/searchPaginated",
        JSON.stringify(body)
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((response) => {
            setData(response.data);
            setTotal(response.total);
            setReady(true);
          });
        } else {
          res.json().then((err) => {
            dispatch(
              showSnackbar(
                "Erreur lors de la recherche : " + err.message,
                "error"
              )
            );
          });
        }
      });
    }
  };
  return (
    <Card
      elevation={0}
      component="main"
      sx={{
        backgroundColor: "white",
        m: 2,
        p: 2,
        borderRadius: "10px",
        height: "calc(100vh - 100px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FiltersWrapper
        token={token}
        setHistoryData={setData}
        ready={ready}
        setReady={setReady}
        postSearch={postSearch}
        currentNames={currentNames}
        setCurrentNames={setCurrentNames}
        currentActions={currentActions}
        setCurrentActions={setCurrentActions}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        // currentEndpoint={currentEndpoint}
        // setCurrentEndpoint={setCurrentEndpoint}
        currentEntities={currentEntities}
        setCurrentEntities={setCurrentEntities}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <HistoryGrid
        historyData={data}
        ready={ready}
        total={total}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      {!ready && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
};

export default History;
