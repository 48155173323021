import { SESSION_START, SESSION_END } from "../actions/types";

interface userState {
  isLogged: boolean;
  token?: string;
  username?: string;
}

const userState: userState = {
  isLogged: false,
};

export default function userReducer(state = userState, action: any) {
  switch (action.type) {
    case SESSION_START:
      let token: string = action.payload.token;
      let username: string = action.payload.username;
      return {
        token,
        username,
        // Si j'utilise ceci divise le token ?
        // ...action.payload,
        isLogged: true,
      };

    case SESSION_END:
      return {
        isLogged: false,
      };

    default:
      return state;
  }
}
