// React
import { useEffect, useState } from "react";

// Material UI
import { Box, Alert, CircularProgress, Dialog, TextField } from "@mui/material";
import { Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Tools
import { useNavigate } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import {
  addCharges,
  sessionEnd,
  addTask,
  getLotData,
  initCalculCost,
  initEmptyCosts,
  showSnackbar,
  calculAll,
  calculTCosts,
} from "../../redux/actions/index";

// Interface
import { ITask } from "../../interface/task";
import { ILot } from "../../interface/lot";
import { MatriceWrapper } from "../../interface/matrice";

// Composant
import TaskDetails from "../../components/Tasks/TaskDetails";
import LotCosts from "../../components/Lots/LotCosts";
import AddLot from "../../components/Lots/AddLot";
import { matrixFormat } from "../../components/Matrix/MatrixFormat";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";

// Fetch
import { fetchPutProtect } from "../../fetchs/put";
import { deleteOneLot } from "../../fetchs/delete";
import { getOne } from "../../fetchs/get";

// Tools
import useWindowDimensions from "../../tools/useWindowDimensions";

// Icons
import GridOnIcon from "@mui/icons-material/GridOn";
import AddIcon from "@mui/icons-material/Add";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';

const LotDetails = ({
  projectId,
  lotId,
  setLotId,
  showDialog,
  setShowDialog,
  refreshProject
}: {
  projectId: string;
  lotId: string;
  setLotId: Function;
  showDialog: boolean;
  setShowDialog: Function;
  refreshProject: Function;
}) => {
  let tasks: Array<ITask> = useSelector((state: RootState) => {
    return state.lotSynthesis.tasks;
  });

  let charges: Array<ITask> = useSelector((state: RootState) => {
    return state.lotSynthesis.charges;
  });

  let tasksCharges: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.tasksCharges;
  });

  let totalCharges: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.totalCharges;
  });

  let PPR: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.PPR;
  });

  let lotCosts: Array<number> = useSelector((state: RootState) => {
    return state.lotSynthesis.lotCosts;
  });

  let total: number | undefined = useSelector((state: RootState) => {
    return state.lotSynthesis.total;
  });

  const [error, setError] = useState<string>("");
  const [openModalLot, setOpenModalLot] = useState<boolean>(false);
  const [openModalLotEdit, setOpenModalLotEdit] = useState<boolean>(false);
  const [lot, setLot] = useState<ILot | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [tempLot, setTempLot] = useState<ILot | undefined>(undefined);
  const [editedLotName, setEditedLotName] = useState<string | undefined>(lot?.name);
  const [editingLotName, setEditingLotName] = useState<boolean>(false);
  const [syncMatrice, setSyncMatrice] = useState<boolean>(false);
  const [validDelete, setValidDelete] = useState(false);
  const { width } = useWindowDimensions();
  let token = useSelector((state: RootState) => state.user.token);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    setShowDialog(false);
  }, []);

  useEffect(()=>{
    if(validDelete == true){
      updateLot()
      setValidDelete(false)
    }

  },[validDelete])

  useEffect(() => {
    if (lot && lot.tasks.length > 0) {
      /*Add charges permet de mettre a jour la matrice dans la redux*/
      dispatch(addCharges(lot.charges));
      dispatch(getLotData(lot.tasks, lot.PPR, lot.tasksCharges, lot.totalCharges, lot.lotCosts, lot.total));
      dispatch(initCalculCost());
    } else {
      dispatch(initEmptyCosts());
    }
  }, [lot, lotId]);

  useEffect(() => {
    if (openModalLot) setLot(lot);
  }, [openModalLot]);

  useEffect(() => {
    getLot();
  }, [lotId]);

  useEffect(() => {
    if (valid) setValid(!valid);
  }, [valid]);

  const handleShowDialog = (value: boolean) => {
    setShowDialog(value);
  };

  const handleNameChange = (newName: string) => {
    setEditingLotName(true);
    setEditedLotName(newName);
    handleShowDialog(true);
  };

  /* Requête pour récupérer un lot en fonction de son Id + envoyer la matrice dans le redux*/
  const getLot = () => {
    if (token) {
      getOne(
        token,
        "/lots/",
        lotId,
        (lot: ILot) => {
          setLot(lot);
          setEditedLotName(lot.name);
          if (token)
            getOne(
              token,
              "/matrice/id/",
              lot.matrice,
              (matrice: MatriceWrapper) => {
                dispatch(addCharges(matrixFormat(matrice.data)));
              },
              () => dispatch(sessionEnd()),      
            );
        },
        () => dispatch(sessionEnd()),
        (err: any) => { 
           navigate(`/projets/`);
           dispatch(showSnackbar("Erreur lors de la recuperation d'un lot : " + err?.error, "error"));
         }
      );
    }
  };

  const updateLot = () => {
    if (lot) {
      const body = JSON.stringify({
        projectId: projectId,
        lotId: lot._id,
        name: editedLotName || lot.name,
        matrice: lot.matrice,
        tasks: tasks,
        charges: charges,
        tasksCharges: tasksCharges,
        PPR: PPR,
        totalCharges: totalCharges,
        lotCosts: lotCosts,
        total: total,
      });
      if (token) {
        fetchPutProtect(token, `/lots/update`, body).then((res) => {
          if (res.status === 200) {
            // refreshProject();                                                        correction ticket #35
            dispatch(showSnackbar("Le lot a bien été modifié", "success"));
          } else if (res.status === 401) {
            dispatch(sessionEnd());
          } else {
            res.json().then((err) => {
              setError(err.error);
              dispatch(showSnackbar("Erreur lors de la modification du lot : " + err.error, "error"));
            });
          }
        });
      }
    }
  };

  /**
   * @param lotId
   * Fonction permettant de supprimer un lot dans le tableau lot de la table projet
   * ainsi que dans la table lots
   * */
  const deleteLot = (lotId: string) => {
    let projectId = window.location.pathname.split("/")[2];
    if (token) {
      deleteOneLot(token, `/projects/delete/${projectId}/${lotId}`).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Le lot a bien été supprimé", "success"));
          setLotId("");
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            navigate(`/projets/`);
            setError(err.error);
            dispatch(showSnackbar("Erreur lors de la suppression du lot : " + err.error, "error"));
          });
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  //fonction qui permet de recalculer les valeurs en fonction de la nouvelle matrice
  const handleSyncLot = () => {
    getLot();
    setTimeout(() => {
      dispatch(calculAll());
      dispatch(calculTCosts());
      updateLot();
    }, 1000)
  }

  return (
    <Box margin={width && width > 1600 ? 5 : 0}>
      {lot && (
        <AddLot lot={tempLot || lot} setLot={(newLot: ILot) => { setLot(newLot); setTempLot(newLot); }} openModal={openModalLot} setOpenModal={setOpenModalLot} syncLotMatrice={handleSyncLot} />
      )}

      {error && <Alert severity="error">{error}</Alert>}

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Button
          onClick={() => {
            dispatch(addTask());
          }}
          startIcon={<AddIcon />}
          sx={{ fontWeight: "bold" }}
          variant="outlined"
        >
          Ajouter une tâche
        </Button>

        <Box sx={{ display: "flex" }}>
          <Button
            onClick={() => {
              setSyncMatrice(true);
              setOpenModalLot(true);
            }}
            startIcon={<GridOnIcon />}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Matrice du lot
          </Button>

          {/* Utilisez un champ de texte pour le nom du lot */}
          <TextField
            variant="outlined"
            value={editedLotName}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </Box>
      </Box>

      {lot ? (
        <Fade in={true}>
          <div>
            <TaskDetails
              lotId={lotId}
              setLotId={setLotId}
              modal={openModalLot}
              onSave={valid}
              showDialog={showDialog}
              setShowDialog={handleShowDialog}
              updateLot={() => {
                updateLot();
                setValid(true);
              }}
              setValidDelete={setValidDelete}
            />
            <LotCosts lot={lot._id} />
            <Button
              variant="contained"
              onClick={() => {
                updateLot();
                setValid(true);
              }}
              sx={{ mr: 2, mb: 2, mt: 5 }}
              startIcon={<SendTwoToneIcon />}
            >
              Enregistrer
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
              sx={{ mb: 2, mt: 5 }}
              color="error"
            >
              Supprimer
            </Button>

            {open && (
              <AlerteDialog
                open={open}
                handleClose={handleClose}
                deleteProject={() => {
                  deleteLot(lot._id);
                }}
                title={"Souhaitez vous vraiment supprimer ce lot ?"}
                contentText={"Attention vous êtes sur le point de supprimer définitivement ce lot."}
              />
            )}

            <Dialog open={openModalLotEdit}>
              <DialogTitle>Modifier le lot</DialogTitle>
              <DialogContent sx={{ mr: 5 }}>
                {/* Utilisez un champ de texte pour le nom du lot */}
                <TextField
                  label="Nom du lot"
                  variant="outlined"
                  value={editedLotName || ""}
                  onChange={(e) => handleNameChange(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => setOpenModalLotEdit(false)}>
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditingLotName(false);
                    setOpenModalLotEdit(false);
                  }}
                >
                  Valider
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Fade>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          Chargement ...
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default LotDetails;